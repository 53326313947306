import { template } from "lodash"
import Moment from "moment"
import { extendMoment } from "moment-range"

// @ts-ignore
const moment = extendMoment(Moment)

const MINUTE_IN_MS = 1000 * 60
const HOUR_IN_MS = MINUTE_IN_MS * 60
const DAY_IN_MS = HOUR_IN_MS * 24
const WEEK_IN_MS = DAY_IN_MS * 7

export const isWithinTimeRange = (
  startTime: Date,
  endTime: Date,
  event: Date
): boolean => {
  const range = moment.range(startTime, endTime)
  return range.contains(event)
}

export const isInFuture = (date: Date) => date.getTime() > new Date().getTime()
export const isInPast = (date: Date) => date.getTime() < new Date().getTime()

export const isSameDay = (date1: Date, date2: Date = new Date()) =>
  new Date(new Date(date1).setHours(3, 0, 0, 0)).getTime() ===
  new Date(new Date(date2).setHours(3, 0, 0, 0)).getTime()

export const setTime = (date: Date, hours?: number, minutes?: number): Date => {
  const now = new Date()
  const baseDateClone = new Date(date)
  const outpuDate = new Date(
    baseDateClone.setHours(hours || now.getHours(), minutes || now.getMinutes())
  )

  return outpuDate
}

export const addMinutes = (date: Date, minutes: number) =>
  new Date(new Date(date).setMinutes(new Date(date).getMinutes() + minutes))

const generateRelativeString = (
  output: string,
  date: Date,
  forceDate: boolean
) => (forceDate ? `${output} (${getLocalDateString(date)})` : output)
export const getRelativeDateLabel = (
  date: Date,
  daysStrings: string[],
  relativeDates?: object,
  endTime?: Date,
  withDayLabel?: boolean,
  forceDate?: boolean
) => {
  if (endTime && isWithinTimeRange(date, endTime, new Date())) {
    const output = relativeDates?.["now"] || "Now"
    return generateRelativeString(output, date, forceDate)
  }
  if (isSameDay(date, new Date())) {
    const output = relativeDates?.["today"] || "Today"
    return generateRelativeString(output, date, forceDate)
  }

  const msDiff = date.getTime() - new Date().getTime()
  let past = false
  if (msDiff < 0) {
    past = true
  }

  const absDiff = Math.abs(msDiff)

  if (absDiff < DAY_IN_MS) {
    const output = past
      ? relativeDates?.["yesterday"] || "Yesterday"
      : relativeDates?.["tomorrow"] || "Tomorrow"

    return generateRelativeString(output, date, forceDate)
  }
  if (absDiff < WEEK_IN_MS) {
    const output = template(
      past
        ? relativeDates?.["last"] || "Last ${day}"
        : relativeDates?.["next"] || "Next ${day}"
    )({
      day: daysStrings[date.getDay()],
    })
    return generateRelativeString(output, date, forceDate)
  }

  const outputComponents = [
    ...(withDayLabel ? [daysStrings[date.getDay()]] : []),
    getLocalDateString(date),
  ]
  return outputComponents.join(" - ")
}

export const getLocalTimeString = (date: Date): string =>
  date?.toLocaleTimeString?.("he-IL", {
    hour: "numeric",
    minute: "2-digit",
    hourCycle: "h24",
  })
export const getLocalDateString = (
  date: Date,
  withYear?: boolean,
  shortYear?: boolean
): string =>
  date.toLocaleDateString("he-IL", {
    day: "numeric",
    month: "numeric",
    ...(withYear && { year: shortYear ? "2-digit" : "numeric" }),
  })

export const getTimeDiffInMins = (startDate: Date, endDate: Date) => {
  const diffInMs = endDate.getTime() - startDate.getTime()
  const diffInSecs = diffInMs / 1000
  return diffInSecs / 60
}

export const addMonths = (
  months: number,
  startDate: Date = new Date()
): Date => {
  const dateClone = new Date(startDate.toString())

  const newDate = new Date(dateClone.setMonth(dateClone.getMonth() + months))
  return newDate
}

export const calculateAge = (birthday: Date, relativeDates: object): string => {
  const today = new Date()
  const hasBirthdayPassed =
    birthday.getMonth() === today.getMonth()
      ? birthday.getDate() < today.getDate()
      : birthday.getMonth() < today.getMonth()
  const ageInYears = hasBirthdayPassed
    ? today.getFullYear() - birthday.getFullYear()
    : today.getFullYear() - birthday.getFullYear() - 1

  const monthsDiff = today.getMonth() - birthday.getMonth()
  const ageMonths = monthsDiff >= 0 ? monthsDiff : 12 + monthsDiff

  if (monthsDiff !== 0) {
    const monthsString =
      ageMonths === 1
        ? relativeDates?.["month"] || "Month"
        : `${ageMonths} ${relativeDates?.["months"] || "Months"}`
    const outputComponents = [ageInYears, monthsString]
    return outputComponents.join(` ${relativeDates?.["and"] || "And "}`)
  }

  return String(ageInYears)
}
