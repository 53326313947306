import { BASE_URL, post } from "./requests"

const MIGRATE_FROM_ALPHA = `${BASE_URL}/teacher/migrate/alpha`

export const migrateFromAlpha = async (
  email: string,
  password: string,
  teacherUID: number
) => {
  const res = await post(MIGRATE_FROM_ALPHA, { email, password, teacherUID })
  return res
}
