const BackIcon = (props: IconProps) => {
  const { size = 24, color = "#090A0A", ...rest } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M10.4764 11.6464L10.1229 12L10.4764 12.3535L14.3564 16.2335C14.5448 16.4219 14.5484 16.7469 14.3541 16.9487C14.1592 17.1412 13.8475 17.1404 13.6535 16.9464L9.06353 12.3564C8.8688 12.1617 8.8688 11.8483 9.06353 11.6535L13.6535 7.06355C13.8483 6.86881 14.1617 6.86881 14.3564 7.06355C14.5512 7.25829 14.5512 7.5717 14.3564 7.76644L10.4764 11.6464Z"
        stroke={color}
      />
    </svg>
  )
}
export default BackIcon
