import clsx from "clsx"
import Button from "../../components/Button/Button"
import Header from "../../components/Header/Header"
import NavigationBar from "../../components/NavigationBar/NavigationBar"
import TextInput from "../../components/TextInput/TextInput"
import { useCopies } from "../../contexts/CopiesContext"
import classes from "./VerifyOTP.module.scss"
import { BASE_URL, post } from "../../utils/requests"
import { useCounter } from "../../contexts/CounterContext"
import { template } from "lodash"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSettings } from "../../contexts/SettingsContext"

interface VerifyOTPProps {
  title?: string
  subtitle?: string
  onSuccess?(): void
}
const VerifyOTP = (props: VerifyOTPProps) => {
  const { title, subtitle, onSuccess } = props
  const {
    copies: { verify_otp: copies },
  } = useCopies()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [otp, setOtp] = useState()

  const { phoneNumber, count, startCounting } = useCounter()
  const navigate = useNavigate()
  const { setToken } = useSettings()
  const onSubmit = async () => {
    setLoading(true)
    const res = await post(`${BASE_URL}/auth/otp/validate`, {
      phoneNumber,
      otp: Number(otp),
    })
    setLoading(false)

    if (res.error) {
      setError(res.error.message)
      return
    }

    if (!res.token) {
      return
    }

    setToken(res.token)
    if (onSuccess) {
      onSuccess()
    } else {
      navigate("/")
    }
  }

  const onResend = async () => {
    if (count > 0) {
      return
    }

    setLoading(true)
    const res = await post(`${BASE_URL}/auth/otp/create`, { phoneNumber })
    setLoading(false)

    if (res.error) {
      return
    }

    startCounting(phoneNumber)
  }

  useEffect(() => {
    if (!phoneNumber) {
      navigate("/login/phone", { replace: true })
    }
  }, [])
  return (
    <div className={classes.root}>
      <NavigationBar />
      <div className={clsx(classes.wrapper, "padded")}>
        <div className={classes.content}>
          <Header
            title={title ?? copies.title}
            subtitle={subtitle ?? template(copies.subtitle)({ phoneNumber })}
            className={classes.header}
          />
          <TextInput
            placeholder={copies.input_placeholder}
            onChange={setOtp}
            value={otp}
            error={error}
          />
        </div>

        <div className={classes.buttonsWrapper}>
          <Button onClick={onSubmit} className={classes.button}>
            {copies.cta_label}
          </Button>
          <Button
            onClick={onResend}
            variant="text"
            className={classes.button}
            disabled={count > 0}
          >
            {count > 0
              ? template(copies.resend_in_cta_label)({ seconds: count })
              : copies.resend_cta_label}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VerifyOTP
