import { template } from "lodash"
import { useCopies } from "../../contexts/CopiesContext"
import classes from "./EventItem.module.scss"
import ClockIcon from "../../icons/Clock"
import { getLocalDateString, getLocalTimeString } from "../../utils/time"
import CalendarIcon from "../../icons/Calendar"
import clsx from "clsx"

interface EventItemProps {
  event: EventDetails
  indicatorColor?: string
  isActive?: boolean
}
const EventItem = (props: EventItemProps) => {
  const { event, isActive, indicatorColor } = props

  const {
    copies: { event_types, events: copies },
  } = useCopies()

  const eventTypesToReplace: EventType[] = [
    "lessonRequest",
    "cancelationRequest",
  ]
  const eventType: EventType = eventTypesToReplace.includes(event.type)
    ? "lesson"
    : event.type

  return (
    <div className={clsx(classes.root, { [classes.active]: isActive })}>
      <div className={classes.header}>
        {indicatorColor && (
          <div
            style={{
              borderRightWidth: 2,
              height: 20,
              borderRightColor: indicatorColor,
              borderRightStyle: "solid",
            }}
          />
        )}

        <h4 className={classes.title}>
          {template(copies.event_number)({
            eventType: event_types[eventType],
            number: event.count,
          })}
        </h4>
      </div>
      <div className={classes.content}>
        <div className={classes.item}>
          <CalendarIcon />
          {getLocalDateString(event.startTime)}
        </div>
        <div className={classes.item}>
          <ClockIcon />
          {getLocalTimeString(event.startTime)} -{" "}
          {getLocalTimeString(event.endTime)}
        </div>
      </div>
    </div>
  )
}

export default EventItem
