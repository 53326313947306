import clsx from "clsx"
import classes from "./TextInput.module.scss"

interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  placeholder?: string
  error?: string
  helper?: string
  onChange?(value: any): void
}

const TextInput = (props: TextInputProps) => {
  const { placeholder, error, helper, onChange } = props

  const handleChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value
    onChange?.(value)
  }

  return (
    <div className={classes.root}>
      <input
        type="text"
        placeholder={placeholder}
        className={clsx(classes.input, {
          [classes.errorInput]: !!error,
        })}
        onInput={handleChange}
      />
      {error && <p className={classes.error}>{error}</p>}
      {helper && !error && <p className={classes.helper}>{helper}</p>}
    </div>
  )
}

export default TextInput
