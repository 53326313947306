import { useNavigate } from "react-router-dom"
import BackIcon from "../../icons/Back"
import classes from "./NavigationBar.module.scss"
import clsx from "clsx"
import Button from "../Button/Button"
import { track } from "../../utils/track"

interface NavigationBarProps {
  withBack?: boolean
  title?: string
}

const NavigationBar = (props: NavigationBarProps) => {
  const { title, withBack = true } = props

  const navigate = useNavigate()

  const onGoBackClick = () => {
    track("Back Press")
    navigate(-1)
  }
  return (
    <div className={clsx(classes.root, "ltr")}>
      {withBack && (
        <Button onClick={onGoBackClick} variant="text">
          <BackIcon />
        </Button>
      )}
      {title && <span className={classes.title}>{title}</span>}
      <div className={classes.action} />
    </div>
  )
}

export default NavigationBar
