const AddIcon = (props: IconProps) => {
  const { size = 24, color = "white", ...rest } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_318_19)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_318_19">
          <rect width={size} height={size} />
        </clipPath>
      </defs>
    </svg>
  )
}
export default AddIcon
