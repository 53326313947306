import { useSettings } from "../contexts/SettingsContext"
import Home from "../pages/Home"
import AddLesson from "../pages/lessons/AddLesson"
import LessonSummary from "../pages/lessons/LessonSummary"
import Login from "../pages/login/Login"
import PhoneNumber from "../pages/login/PhoneNumber"
import VerifyOTP from "../pages/login/VerifyOTP"
import MigrateFromAlpha from "../pages/public/MigrateFromAlpha/MigrateFromAlpha"
import Sign from "../pages/public/Sign"
import Unsubscribe from "../pages/public/Unsubscribe"
import Settings from "../pages/settings/Settings"
import { getCookie } from "../utils/cookies"

interface RouteProps {
  id: string
  path: string
  element: JSX.Element
}
const loggedOutRoutes: RouteProps[] = [
  {
    id: "login",
    path: "/",
    element: <Login />,
  },
  {
    id: "phone_number",
    path: "/login/phone",
    element: <PhoneNumber />,
  },
  {
    id: "otp",
    path: "/login/otp",
    element: <VerifyOTP />,
  },
]

const loggedInRoutes: RouteProps[] = [
  {
    id: "home",
    path: "/",
    element: <Home />,
  },
  {
    id: "add_lesson",
    path: "/lesson/add",
    element: <AddLesson />,
  },
  {
    id: "lesson_summary",
    path: "/lesson/add/summary",
    element: <LessonSummary />,
  },
  {
    id: "settings",
    path: "/settings",
    element: <Settings />,
  },
]

const publicRoutes: RouteProps[] = [
  {
    id: "sign",
    path: "/sign/:id",
    element: <Sign />,
  },
  {
    id: "unsubscribe",
    path: "/unsubscribe/:number",
    element: <Unsubscribe />,
  },
  {
    id: "migrate/alpha",
    path: "/migrate/alpha",
    element: <MigrateFromAlpha />,
  },
]

const useRoutes = () => {
  const {
    settings: { token },
  } = useSettings()
  let routes = [...publicRoutes]
  if (token) {
    routes = [...routes, ...loggedInRoutes]
    return routes
  }
  routes = [...routes, ...loggedOutRoutes]
  return routes
}

export default useRoutes
