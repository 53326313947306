import { useNavigate } from "react-router-dom"
import CalendarIcon from "../../../icons/Calendar"
import classes from "./BottomNavigationItem.module.scss"
import clsx from "clsx"
import SettingsIcon from "../../../icons/Settings"

type BottomNavigationItemIcon = "calendar" | "settings"
export interface BottomNavigationItemProps {
  label: string
  icon: BottomNavigationItemIcon
  active: boolean
  route: string
}

const iconsMapping: Record<
  BottomNavigationItemIcon,
  (props: IconProps) => JSX.Element
> = {
  calendar: CalendarIcon,
  settings: SettingsIcon,
}
const BottomNavigationItem = (props: BottomNavigationItemProps) => {
  const { label, icon, active, route } = props
  const Icon = iconsMapping[icon] || iconsMapping["calendar"]

  const navigate = useNavigate()

  const onItemClick = () => {
    navigate(route)
  }
  return (
    <div className={classes.root} onClick={onItemClick}>
      <Icon color={active ? "#3366ff" : "#b8b8b8"} />
      <span className={clsx(classes.label, { [classes.active]: active })}>
        {label}
      </span>
    </div>
  )
}

export default BottomNavigationItem
