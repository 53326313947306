import clsx from "clsx"
import classes from "./CalendarStripItem.module.scss"

export interface CalendarStripDayItemProps {
  dayName: string
  date: Date
  marked?: boolean
  disabled?: boolean
  onDateClick?(date: Date): void
  active?: boolean
}

const CalendarStripItem = (props: CalendarStripDayItemProps) => {
  const { onDateClick, dayName, date, active, disabled } = props
  return (
    <div
      className={clsx(classes.root, { [classes.clickDisabled]: disabled })}
      onClick={() => !disabled && onDateClick(date)}
    >
      <span className={classes.dayTitle}>{dayName}</span>
      <div
        className={clsx(classes.dayDate, {
          [classes.active]: active,
          [classes.disabled]: disabled,
        })}
      >
        <span>{date.getDate()}</span>
      </div>
    </div>
  )
}

export default CalendarStripItem
