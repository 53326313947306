import EventItem from "./EventItem"
import classes from "./EventsGroup.module.scss"

interface EventsGroupProps {
  events: EventDetails[]
  title: string
  indicatorColor?: string
  isActive?: boolean
  onEventPress?(event: EventDetails): void
}
const EventsGroup = (props: EventsGroupProps) => {
  const { events, title, indicatorColor, isActive, onEventPress } = props

  const filteredEvents = events.filter(Boolean)
  if (filteredEvents.length < 1) {
    return null
  }

  return (
    <div className={classes.root}>
      <h4>{title}</h4>

      <div className={classes.events}>
        {filteredEvents.map((event) => (
          <EventItem
            event={event}
            indicatorColor={indicatorColor}
            isActive={isActive}
            key={event.id}
          />
        ))}
      </div>
    </div>
  )
}

export default EventsGroup
