const NextIcon = (props: IconProps) => {
  const { size = 24, color = "#090A0A", ...rest } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      transform="rotate(180)"
      {...rest}
    >
      <path
        d="M10.4765 11.6465L10.1229 12L10.4765 12.3536L14.3565 16.2336C14.5448 16.4219 14.5484 16.7469 14.3542 16.9487C14.1593 17.1412 13.8475 17.1404 13.6536 16.9465L9.06356 12.3565C8.86883 12.1617 8.86883 11.8483 9.06356 11.6536L13.6536 7.06356C13.8483 6.86883 14.1617 6.86883 14.3565 7.06356C14.5512 7.2583 14.5512 7.57172 14.3565 7.76646L10.4765 11.6465Z"
        stroke={color}
      />
    </svg>
  )
}
export default NextIcon
