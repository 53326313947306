import { BrowserRouter, Route, Routes } from "react-router-dom"
import useRoutes from "../hooks/useRoutes"
import { useCopies } from "../contexts/CopiesContext"
import { useEffect } from "react"
import { track } from "../utils/track"

const Router = () => {
  const routes = useRoutes()
  const { language } = useCopies()

  useEffect(() => {
    track("Loaded", { platform: "web" })
  }, [])
  return (
    <main className={language === "he" ? "rtl" : "lrt"}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.element} key={route.id} />
          ))}
        </Routes>
      </BrowserRouter>
    </main>
  )
}

export default Router
