import { createContext, useContext, useEffect, useState } from "react"
import { Copies, copies as baseCopies, Language } from "../copies"
import { getCookie, setCookie } from "../utils/cookies"

const COOKIE_KEY = "sys-lang"

interface CopiesContextProps {
  copies: Copies
  setCopies(copies: Copies): void
}

const defaultValue: CopiesContextProps = {
  copies: baseCopies.he,
  setCopies: () => {},
}

const CopiesContext = createContext<CopiesContextProps>(defaultValue)

export const CopiesContextProvider = (props: any) => {
  const [copies, setCopies] = useState(baseCopies.he)
  return (
    <CopiesContext.Provider value={{ copies, setCopies }}>
      {props.children}
    </CopiesContext.Provider>
  )
}

interface UseCopiesRetValue {
  copies: Copies
  changeLanguage(language: Language): void
  language: Language
  isRtl: boolean
}

export const useCopies = (): UseCopiesRetValue => {
  const { copies, setCopies } = useContext(CopiesContext)

  const [language, setLanguage] = useState<Language>("he")

  useEffect(() => {
    const language = getCookie(COOKIE_KEY) as Language
    if (!language) {
      return
    }
    setLanguage(language)
    setCopies(language === "en" ? baseCopies.en : baseCopies.he)
  }, [])

  const changeLanguage = (language: Language) => {
    setCopies(language === "en" ? baseCopies.en : baseCopies.he)
    setCookie(COOKIE_KEY, language)
  }

  return { copies, changeLanguage, language, isRtl: language === "he" }
}
