import clsx from "clsx"
import classes from "./Unsubscribe.module.scss"
import blueLogo from "../../assets/blue-logo.svg"
import Header from "../../components/Header/Header"

import { useCopies } from "../../contexts/CopiesContext"
import { useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { BASE_URL, get, post } from "../../utils/requests"
import { template } from "lodash"
import { formatReadablePhoneNumber } from "../../utils/phoneNumber"
import Switch from "react-switch"
import Button from "../../components/Button/Button"

interface SMSSettings {
  canGetSMS: boolean
  canGetReminderSMS: boolean
  canGetDocumentsSMS: boolean
}

const Unsubscribe = () => {
  const {
    copies: { unsubscribe: copies },
  } = useCopies()

  const [loading, setLoading] = useState(true)

  const [prefrences, setPrefrences] = useState<SMSSettings>()
  const [snackbar, setSnackbar] = useState("")
  const [hideSnackbar, setHideSnackbar] = useState(false)

  const someOn = useMemo(
    () => Object.values(prefrences ?? {}).some(Boolean),
    [prefrences]
  )
  const buttonLabel = useMemo(
    () => (someOn ? copies.all_off : copies.all_on),
    [someOn]
  )

  const params = useParams<"number">()
  const base64PhoneNumber = params.number
  let phoneNumber = ""
  try {
    phoneNumber = formatReadablePhoneNumber(atob(base64PhoneNumber))
  } catch {
    window.location.replace("https://diodrive.co.il")
  }

  const settingsItems = ["canGetSMS", "canGetReminderSMS", "canGetDocumentsSMS"]

  const fetchSettings = async () => {
    const res = await get<SMSSettings>(
      `${BASE_URL}/settings/prefrences/${base64PhoneNumber}`
    )

    if (!("canGetSMS" in res)) {
      window.location.replace("https://diodrive.co.il")
      return
    }
    setLoading(false)
    setPrefrences(res)
  }
  const setSettings = async (settings: SMSSettings) => {
    await post(`${BASE_URL}/settings/prefrences/set`, {
      ...settings,
      number: base64PhoneNumber,
    })

    setSuccessMessage("השינויים נשמרו בהצלחה")
  }

  const onChange = (newPrefrences: SMSSettings) => {
    setPrefrences(newPrefrences)
    setSettings(newPrefrences)
  }
  useEffect(() => {
    fetchSettings()
  }, [])

  const closeSnackbar = (withAnimation: boolean = false) => {
    if (withAnimation) {
      setHideSnackbar(true)
      setTimeout(() => setSnackbar(""), 320)
      setTimeout(() => setHideSnackbar(false), 322)
      return
    }

    setSnackbar("")
  }

  const setSuccessMessage = (text: string) => {
    setSnackbar(text)

    const closeTimeout = 3500
    setTimeout(() => setHideSnackbar(true), closeTimeout)
    setTimeout(closeSnackbar, closeTimeout + 320)
    setTimeout(() => setHideSnackbar(false), closeTimeout + 322)
  }

  const onButtonClick = () => {
    if (someOn) {
      const newPreferences = settingsItems.reduce(
        (prevItem, item) => ({ ...prevItem, [item]: false }),
        {}
      ) as SMSSettings
      setPrefrences(newPreferences)
      setSettings(newPreferences)
      return
    }

    const newPreferences = settingsItems.reduce(
      (prevItem, item) => ({ ...prevItem, [item]: true }),
      {}
    ) as SMSSettings
    setPrefrences(newPreferences)
    setSettings(newPreferences)
  }

  if (loading) {
    return (
      <div className={classes.root}>
        <Header title="טוען..." />
      </div>
    )
  }

  return (
    <>
      <div className={classes.root}>
        <div className={clsx(classes.header, "padded")}>
          <a
            className={classes.logoWrapper}
            href="https://diodrive.co.il"
            target="_blank"
          >
            <img src={blueLogo} className={classes.logo} />
            <div className={classes.dioTexts}>
              <h1>Dio</h1>
            </div>
          </a>
          <Header
            title={copies.title}
            subtitle={template(copies.subtitle)({ phoneNumber })}
          />

          <div className={classes.content}>
            <div className={classes.settings}>
              {settingsItems.map((settingsItem) => (
                <label className={classes.settingsItem} key={settingsItem}>
                  <span>{copies.labels[settingsItem]}</span>
                  <Switch
                    checked={prefrences?.[settingsItem]}
                    onChange={(checked) =>
                      onChange({ ...prefrences, [settingsItem]: checked })
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#3366ff"
                    offColor="#CDCFD0"
                  />
                </label>
              ))}
            </div>
            <div className={classes.divider} />
            <Button
              variant="outlined"
              color={someOn ? "error" : "primary"}
              onClick={onButtonClick}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
      {!!snackbar && (
        <div
          className={clsx(classes.snackbar, {
            [classes.active]: !!snackbar,
            [classes.hide]: hideSnackbar,
          })}
        >
          <span>{snackbar}</span>
          <Button
            variant="text"
            color="white"
            className={classes.action}
            onClick={() => closeSnackbar(true)}
          >
            סגירה
          </Button>
        </div>
      )}
    </>
  )
}
export default Unsubscribe
