import { useMemo, useState } from "react"
import { isSameDay, setTime } from "../utils/time"
import { CalendarStripDayItemProps } from "../components/CalendarStrip/CalendarStripItem"

const daysMapping = {
  0: "SAN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
}

const compareDates = (dateA: Date, dateB: Date): boolean => {
  const normalizedDateA = new Date(new Date(dateA).setHours(0, 0, 0, 0))
  const normalizedDateB = new Date(new Date(dateB).setHours(0, 0, 0, 0))

  return normalizedDateA.getTime() === normalizedDateB.getTime()
}

interface RetValue {
  days: CalendarStripDayItemProps[]
  page: number
  setActiveDate(date: Date): void
  goToPreviousPage(): void
  goToNextPage(): void
}

interface UseCalendarDaysProps {
  daysStrings: [string, string, string, string, string, string, string]
  firstDayOfWeek?: number
}

const useCalendarDays = (props: UseCalendarDaysProps): RetValue => {
  const { firstDayOfWeek = 0, daysStrings = daysMapping } = props
  const [activeDate, setActiveDate] = useState<Date>(new Date())
  const [page, setPage] = useState<number>(0)

  const goToPreviousPage = () => {
    setPage((page) => page - 1)
  }
  const goToNextPage = () => {
    setPage((page) => page + 1)
  }

  const setActiveDateHandler = (date: Date) => {
    setActiveDate(date)
    setPage(0)
  }

  const days = useMemo<CalendarStripDayItemProps[]>(() => {
    const daysOutput: CalendarStripDayItemProps[] = []

    for (let i = firstDayOfWeek; i < 7 + firstDayOfWeek; i++) {
      const weeksOffset = 7 * page
      const daysOffset = i - activeDate.getDay()
      const offset = daysOffset + weeksOffset
      const selectedDate = new Date(activeDate)
      const date = new Date(
        selectedDate.setDate(selectedDate.getDate() + offset)
      )

      const absoluteDate = isSameDay(date, new Date())
        ? setTime(date)
        : new Date(date.setHours(3, 0, 0, 0))

      daysOutput.push({
        date: absoluteDate,
        dayName: daysStrings[date.getDay()],
        active: compareDates(date, activeDate),
      })
    }
    return daysOutput
  }, [page, activeDate])

  return {
    days,
    setActiveDate: setActiveDateHandler,
    goToPreviousPage,
    goToNextPage,
    page,
  }
}

export default useCalendarDays
