import { useEffect } from "react"
import { useCopies } from "../../contexts/CopiesContext"
import useCalendarDays from "../../hooks/useCalendarDays"
import classes from "./CalendarStrip.module.scss"
import clsx from "clsx"
import { isInFuture, isInPast, isSameDay } from "../../utils/time"
import CalendarStripItem from "./CalendarStripItem"
import Button from "../Button/Button"
import BackIcon from "../../icons/Back"
import NextIcon from "../../icons/NextIcon"

interface CalendarStripProps {
  activeDate?: Date
  firstDayOfWeek?: number
  futureOnly?: boolean
  onDateChange?(date: Date): void
}

const CalendarStrip = (props: CalendarStripProps) => {
  const { onDateChange, activeDate, futureOnly, firstDayOfWeek = 0 } = props
  const {
    copies: { days: daysStrings },
    isRtl,
  } = useCopies()
  const { page, days, setActiveDate, goToNextPage, goToPreviousPage } =
    useCalendarDays({ daysStrings: daysStrings.short, firstDayOfWeek })

  useEffect(() => {
    if (activeDate) {
      setActiveDate(activeDate)
    }
  }, [activeDate])

  const onDateClick = (date: Date) => {
    setActiveDate(date)
    onDateChange?.(date)
  }

  const backDisabled = futureOnly && days.some(({ date }) => isSameDay(date))

  return (
    <div className={classes.root}>
      <Button variant="text" onClick={goToPreviousPage} disabled={backDisabled}>
        {isRtl ? (
          <NextIcon
            {...(backDisabled && {
              color: "#E3E5E5",
            })}
          />
        ) : (
          <BackIcon
            {...(backDisabled && {
              color: "#E3E5E5",
            })}
          />
        )}
      </Button>
      {days.map((day) => (
        <CalendarStripItem
          dayName={day.dayName}
          date={day.date}
          disabled={futureOnly && isInPast(day.date) && !isSameDay(day.date)}
          onDateClick={onDateClick}
          active={day.active}
        />
      ))}
      <Button variant="text" onClick={goToNextPage}>
        {isRtl ? <BackIcon /> : <NextIcon />}
      </Button>
    </div>
  )
}

export default CalendarStrip
