import { useEffect } from "react"
import { CopiesContextProvider } from "./contexts/CopiesContext"
import { CounterContextProvider } from "./contexts/CounterContext"
import { SettingsContextProvider } from "./contexts/SettingsContext"
import Router from "./pages"

function App() {
  return (
    <CopiesContextProvider>
      <SettingsContextProvider>
        <CounterContextProvider>
          <Router />
        </CounterContextProvider>
      </SettingsContextProvider>
    </CopiesContextProvider>
  )
}

export default App
