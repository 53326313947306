import { createContext, useContext, useEffect, useState } from "react"
import { getCookie } from "../utils/cookies"

interface CounterContextProps {
  count: number
  setCount: React.Dispatch<React.SetStateAction<number>>
  phoneNumber: string
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
}

const defaultValue: CounterContextProps = {
  count: 0,
  setCount: () => {},
  phoneNumber: "",
  setPhoneNumber: () => {},
}

const CounterContext = createContext<CounterContextProps>(defaultValue)

export const CounterContextProvider = (props: any) => {
  const [count, setCount] = useState<number>(defaultValue.count)
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultValue.phoneNumber
  )

  return (
    <CounterContext.Provider
      value={{ count, setCount, phoneNumber, setPhoneNumber }}
    >
      {props.children}
    </CounterContext.Provider>
  )
}

export const useCounter = () => {
  const { count, setCount, phoneNumber, setPhoneNumber } =
    useContext(CounterContext)

  const startCounting = (phoneNumber: string, startFrom: number = 30) => {
    setPhoneNumber(phoneNumber)
    setCount(startFrom)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (count > 0) {
      timeout = setTimeout(() => {
        setCount((prevCount) => prevCount - 1)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [count])

  return { count, phoneNumber, startCounting }
}
