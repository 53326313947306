import clsx from "clsx"
import classes from "./Header.module.scss"
import Button from "../Button/Button"
import ClockIcon from "../../icons/Clock"

interface HeaderProps {
  title: string
  subtitle?: string
  className?: string
  onCtaClick?(): void
  ctaLabel?: string
  ctaStartIcon?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const { title, subtitle, className, ctaLabel, ctaStartIcon, onCtaClick } =
    props

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <h1 className={classes.title}>{title}</h1>
        {subtitle && <h4 className={classes.subtitle}>{subtitle}</h4>}
      </div>
      {ctaLabel && onCtaClick && (
        <Button startIcon={ctaStartIcon} onClick={onCtaClick}>
          {ctaLabel}
        </Button>
      )}
    </div>
  )
}

export default Header
