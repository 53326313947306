import classes from "./Login.module.scss"
import WhiteLogo from "../../assets/white-logo.svg"
import { useCopies } from "../../contexts/CopiesContext"
import Button from "../../components/Button/Button"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const {
    copies: { homepage_content: copies },
  } = useCopies()

  const navigate = useNavigate()

  const onGetStartedClick = () => {
    navigate("/login/phone")
  }
  return (
    <div className={classes.root}>
      <img src={WhiteLogo} />
      <h1 className={classes.title}>{copies.title}</h1>
      <Button variant="white" onClick={onGetStartedClick}>
        {copies.cta}
      </Button>
    </div>
  )
}

export default Login
