import { parsePhoneNumber } from "awesome-phonenumber"

export const REGION_CODE = "IL"

export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  parsePhoneNumber(phoneNumber, { regionCode: REGION_CODE }).valid

export const formatPhoneNumber = (phoneNumber: string): string =>
  parsePhoneNumber(phoneNumber, { regionCode: REGION_CODE }).number?.e164 ?? ""

export const formatReadablePhoneNumber = (phoneNumber: string): string =>
  parsePhoneNumber(phoneNumber, { regionCode: REGION_CODE }).number?.national ??
  ""
