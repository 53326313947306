import clsx from "clsx"
import Header from "../../components/Header/Header"
import NavigationBar from "../../components/NavigationBar/NavigationBar"
import TextInput from "../../components/TextInput/TextInput"
import { useCopies } from "../../contexts/CopiesContext"
import classes from "./PhoneNumber.module.scss"
import Button from "../../components/Button/Button"
import { useState } from "react"
import { BASE_URL, post } from "../../utils/requests"
import { useNavigate } from "react-router-dom"
import { useCounter } from "../../contexts/CounterContext"

interface PhoneNumberProps {
  title?: string
  subtitle?: string
  onSuccess?(): void
}
const PhoneNumber = (props: PhoneNumberProps) => {
  const { title, subtitle, onSuccess } = props
  const {
    copies: { login_phone_number: copies },
  } = useCopies()

  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { startCounting, phoneNumber: counterPhone } = useCounter()

  const onSubmit = async () => {
    setLoading(true)
    const res = await post(`${BASE_URL}/auth/otp/create`, { phoneNumber })
    setLoading(false)

    if (res.error) {
      setError(res.error.message)
      return
    }

    if (res.success) {
      if (counterPhone !== phoneNumber) {
        startCounting(phoneNumber)
      }
      if (!!onSuccess) {
        onSuccess()
      } else {
        navigate("/login/otp")
      }
    }
  }

  const onChange = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber)
    if (error) {
      setError("")
    }
  }

  return (
    <div className={classes.root}>
      <NavigationBar />
      <div className={clsx(classes.wrapper, "padded")}>
        <div className={classes.content}>
          <Header
            title={title ?? copies.title}
            subtitle={subtitle ?? copies.subtitle}
            className={classes.header}
          />
          <TextInput
            placeholder={copies.input_placeholder}
            onChange={onChange}
            value={phoneNumber}
            error={error}
            helper={copies.input_disclaimer}
          />
        </div>
        <Button onClick={onSubmit} className={classes.button} loading={loading}>
          {copies.cta_label}
        </Button>
      </div>
    </div>
  )
}

export default PhoneNumber
