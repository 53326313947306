import { useCopies } from "../../contexts/CopiesContext"
import classes from "./BottomNavigation.module.scss"
import BottomNavigationItem, {
  BottomNavigationItemProps,
} from "./BottomNavigationItem/BottomNavigationItem"

const BottomNavigation = () => {
  const {
    copies: { lessons, settings },
  } = useCopies()

  const route = window.location.pathname
  const items: BottomNavigationItemProps[] = [
    {
      label: lessons.title,
      icon: "calendar",
      route: "/",
      active: route === "/",
    },
    {
      label: settings.title,
      icon: "settings",
      route: "/settings",
      active: route === "/settings",
    },
  ]
  return (
    <div className={classes.root}>
      {items.map((item) => (
        <BottomNavigationItem {...item} />
      ))}
    </div>
  )
}

export default BottomNavigation
